#brandpre {
    color: rgb(34, 194, 34) !important;
}

.amenu-1 {
    /* color: hsl(120, 70%, 45%) !important; */
    /* color: '#b80707' !important; */
    /* color: rgb(184, 7, 7) !important; */
    color: rgb(10, 62, 107) !important;
}

.amenu-2 {
    /* color: rgb(29, 107, 10) !important; */
    color: rgb(10, 62, 107) !important;
}

.amenu-3 {
    color: rgb(10, 62, 107) !important;
}

.amenu-4 {
    /* color: rgb(62, 10, 107) !important; */
    /* color: rgb(184, 7, 7) !important; */
    color: rgb(10, 62, 107) !important;
}

.validation-msg {
    color: #ed1313;
}

.notification-bell {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.shake {
    animation: shake-animation 0.5s ease-in-out infinite alternate;
}

@keyframes shake-animation {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(5deg);
    }
}

.nav-style {
    display: flex !important;
    flex-wrap: inherit !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: start !important;
}

/* .menu1 {
    margin-right: 10px !important;
}

.menu2 {
    margin-right: 10px;
    
}
.menu3 {
    margin-right: 10px;
    
}
.menu4 {
    margin-right: 10px;
    
} */